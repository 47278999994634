import React from 'react'
import Logo from '../../images/logo192.png'
import {Link}  from 'react-router-dom'
import MobileMenu from '../../components/MobileMenu'
import './style.css'

const Header = (props) => {
    const SubmitHandler = (e) =>{
        e.preventDefault()
     }

     const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(	
        <div className="middle-header">
            <div className={`header-style-3 ${props.hClass}`}>
                <div className="container-fluid">
                    <div className="header-content">
                    <div className="row align-items-center">
                        <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4">
                            <div className="logo">
                                <Link onClick={ClickHandler} to="/home" title=""><img src={Logo} alt=""/></Link>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-8 d-lg-block d-none">
                            <nav>
                                <ul>
                                    <li><Link onClick={ClickHandler} className="active" to="/home" title="">Home</Link>
                                        <ul>
                                            <li><Link onClick={ClickHandler} to="/cherdchai">Cherdchai</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link onClick={ClickHandler} to="/destination" title="">Destinations</Link>
                                        <ul>
                                            <li><Link onClick={ClickHandler} to="/destination2">All Destinations</Link></li>
                                        </ul>
                                    </li>    
                                    <li><Link onClick={ClickHandler} to="/destination" title="">OG HOT PIX</Link>
                                        <ul>
                                            <li><a onClick={ClickHandler} href="https://booking.ogtix.asia/bus/bangkok/phuket/6056">Bangkok-Phuket</a></li>
                                            <li><a onClick={ClickHandler} href="https://booking.ogtix.asia/bus/bangkok/surat-thani/6119">Bangkok-Surathani</a></li>
                                            <li><a onClick={ClickHandler} href="https://booking.ogtix.asia/bus/bangkok/chiang-mai/3494">Bangkok-Chiang Mai</a></li>
                                            <li><a onClick={ClickHandler} href="https://booking.ogtix.asia/bus/suvarnabhumi-airport/koh-chang/178375">Bangkok-Koh Chang</a></li>
                                            <li><a onClick={ClickHandler} href="https://booking.ogtix.asia/bus/bangkok-bus-terminal-ekkamai/koh-kood-sueadum-go-pier-ao-salad/256159">Bangkok-Koh Kood</a></li>
                                        </ul>
                                    </li>
                                    <li><Link onClick={ClickHandler} to="/recommendations-phuket" title="">Recommendations</Link>
                                        <ul>
                                            <li><Link onClick={ClickHandler} to="/recommendations-phuket">Phuket</Link></li>
                                        </ul>
                                    </li>
                                        <ul>
                                            <li><Link onClick={ClickHandler} to="/about">About</Link></li>
                                        </ul>
                                    <li><Link onClick={ClickHandler} to="/blog">Blog</Link>
                                        <ul>
                                            <li><Link onClick={ClickHandler} to="/blog-fullwidth">Blog</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link onClick={ClickHandler} to="/contact" title="">Contact</Link></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-xl-3 get-q">
                            <div className="get-quote">
                                <Link to="/contact"><i className="fi flaticon-support-1"></i> +66(0)2-269-6999</Link>
                            </div>
                        </div>
                        <div className="col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">
                            <div className="contact">
                                <div className="cart-search-contact">
                                    <div className="header-search-form-wrapper">
                                        
                                        <div className="header-search-form">
                                            <form onSubmit={SubmitHandler}>
                                                <div>
                                                    <input type="text" className="form-control" placeholder="..."/>
                                                    <button type="submit"><i className="ti-search"></i></button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-2 col-2">
                            <MobileMenu/>
                        </div>
                    </div>
                    
                        <div className="clearfix"></div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Header;