import React from 'react';
import './style.css';

const SearchSectionCC = (props) => {
  return (
    <div className={`wpo-select-section ${props.selectClass}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="wpo-select-wrap">
              <div className="wpo-select-area">
                <h2 className="search-header">Know Where You Are Going?</h2>
                {/* Include the BusX search form placeholder */}
                <div
                  id="busx-search-form"
                  data-url="https://booking.ogtix.asia"
                  data-appkey="6bd86abb58a32d1331f25a74afac1a56"
                  data-locale="en_us">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchSectionCC;