import React from 'react';
import { Link } from 'react-router-dom';
import dsn1 from '../../images/destination/kohkood.jpg';
import dsn2 from '../../images/destination/kohchang.jpg'; //Koh Mak
import dsn3 from '../../images/destination/chiangrai.jpg';
import dsn4 from '../../images/destination/chiangmai.jpeg';
import dsn5 from '../../images/destination/nakhon_phanom.jpg'; 
import dsn6 from '../../images/destination/ubonratchathani2.jpg'; 
import dsn7 from '../../images/destination/isan.jpg'; // Change to actual image Surin
import dsn8 from '../../images/destination/northern_thailand.jpg'; // Change to actual image mukdahan
import dsn9 from '../../images/destination/isan.jpg';
import dsn10 from '../../images/destination/green-rice-paddy-fields-in-central-thailand-suphan.jpg'; 
import dsn11 from '../../images/destination/nong_khai.jpg';
import dsn12 from '../../images/destination/chiangmai.jpeg'; // Change to actual image khonkaen
import './style.css';

// Destination data array
const destinations_cc = [
  {
    id: 1,
    image: dsn1,
    link: 'https://booking.ogtix.asia/bus/bangkok-bus-terminal-ekkamai/koh-kood-sueadum-go-pier-ao-salad/256159',
    title: 'Bangkok ↔ Koh Kood',
    frequency: 'Daily',
    price: '1129THB',
  },  
  {
    id: 2,
    image: dsn2,
    link: 'https://booking.ogtix.asia/bus/bangkok-bus-terminal-ekkamai/koh-mak-ao-nid-pier-sueadum-go/256166',
    title: 'Bangkok ↔ Koh Mak',
    frequency: 'Daily',
    price: '1079THB',
  },  
  {
    id: 3,
    image: dsn3,
    link: 'https://booking.ogtix.asia/bus/bangkok-bus-terminal-chatuchak-mo-chit-2/chiang-rai-bus-terminal-2/834',
    title: 'Bangkok ↔ Chiang Rai',
    frequency: 'Daily',
    price: '650THB',
  },
  {
    id: 4,
    image: dsn4,
    link: 'https://booking.ogtix.asia/bus/bangkok-bus-terminal-chatuchak-mo-chit-2/chiang-mai-bus-terminal-3-arcade/3494',
    title: 'Bangkok ↔ Chiang Mai',
    frequency: 'Daily',
    price: '595THB',
  },
  {
    id: 5,
    image: dsn5,
    link: 'https://booking.ogtix.asia/bus/bangkok-bus-terminal-chatuchak-mo-chit-2/nakhon-phanom-bus-terminal/442',
    title: 'Bangkok ↔ Nakhon Phanom',
    frequency: 'Daily',
    price: '675THB',
  },
  {
    id: 6,
    image: dsn6,
    link: 'https://booking.ogtix.asia/bus/bangkok-bus-terminal-chatuchak-mo-chit-2/ubon-ratchathani-bus-terminal/827',
    title: 'Phuket ↔ Ubon Ratchathani',
    frequency: 'Daily',
    price: '544THB',
  },
  {
    id: 7,
    image: dsn7,
    link: 'https://booking.ogtix.asia/bus/bangkok-bus-terminal-chatuchak-mo-chit-2/khon-kaen-bus-terminal-3/365',
    title: 'Bangkok ↔ Khon Kaen',
    frequency: 'Daily',
    price: '380THB',
  },
  {
    id: 8,
    image: dsn8,
    link: 'https://booking.ogtix.asia/bus/bangkok-bus-terminal-chatuchak-mo-chit-2/sisaket-bus-terminal/708',
    title: 'Bangkok ↔ Sisaket',
    frequency: 'Daily',
    price: '413THB',
  },
  {
    id: 9,
    image: dsn9,
    link: 'https://booking.ogtix.asia/bus/bangkok-bus-terminal-chatuchak-mo-chit-2/kap-choeng/4579',
    title: 'Bangkok ↔ Surin',
    frequency: 'Daily',
    price: '346THB',
  },
  {
    id: 10,
    image: dsn10,
    link: 'https://booking.ogtix.asia/bus/bangkok-bus-terminal-chatuchak-mo-chit-2/mukdahan-bus-terminal/631',
    title: 'Bangkok ↔ Mukdahan',
    frequency: 'Daily',
    price: '535THB',
  },
  {
    id: 11,
    image: dsn11,
    link: 'https://booking.ogtix.asia/bus/bangkok-bus-terminal-chatuchak-mo-chit-2/nong-khai-bus-terminal/778',
    title: 'Bangkok ↔ Nong Khai',
    frequency: 'Daily',
    price: '515THB',
  },
  {
    id: 12,
    image: dsn12,
    link: 'https://booking.ogtix.asia/bus/bangkok-bus-terminal-chatuchak-mo-chit-2/nong-khai-bus-terminal/778',
    title: 'Bangkok ↔ Nong Khai',
    frequency: 'Daily',
    price: '515THB',
  },
  // Add more destinations as needed
];

const DestinationsCherdchai = () => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="destination-area section-padding">
      <div className="container">
        <div className="row">
          {/* Optional Section Title */}
          <div className="col-12">
            <div className="wpo-section-title">
              <h2>Popular Destinations</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {destinations_cc.map((destinations_cc) => (
            <div key={destinations_cc.id} className="col-sm-6 col-md-4 col-lg-3">
              <a
                href={destinations_cc.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={ClickHandler}
                className="destination-link"
              >
                <div className="destination-item">
                  <div className="destination-img">
                    <img src={destinations_cc.image} alt={destinations_cc.title} />
                  </div>
                  <div className="destination-content">
                    <div className="content-left">
                      <h5>{destinations_cc.title}</h5>
                      <small>{destinations_cc.frequency}</small>
                    </div>
                    <div className="content-right">
                      <h5>{destinations_cc.price}</h5>
                      <span>Starting</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
        <div className="btns text-center">
          <Link onClick={ClickHandler} to="/destination" className="theme-btn-s2">
            Discover More
          </Link>
        </div>
      </div>
    </div>
    
  );
};

export default DestinationsCherdchai;
