import React from 'react'
import dimg from '../../../images/recommendations/lantern.jpg'


const LanternDescription = (props) => {

    return(
        <div className="service-single-des">
            <div className="service-single-img">
                <img src={dimg} alt="" />
            </div>
            <h2>The Lantern Hostel - Recommended in South Phuket!</h2>
            <p>Welcome to The Lantern Hostel, our recommendation in South Phuket for travelers who want comfort, fun, and unbeatable convenience.</p>
            <p>Located in the vibrant heart of Chalong, this hostel sits just minutes away from Soi Ta-iad—Phuket’s renowned “fight street,” famous for top-tier Muay Thai and MMA gyms—and the revered Wat Chalong, a must-visit cultural landmark on the island.</p>                   
            <p>But the allure goes far beyond the rooms. The Lantern Hostel keeps the good times rolling with a host of amenities. Grab a hearty meal at the “99 baht noodle buffet” challenge a fellow traveler to a friendly match at the pool table or foosball, and wind down in the common area with fresh herbs from the on-site herb shop. If gaming is your scene, fire up the PlayStation and let the competition begin!
                We at OGtix.asia are thrilled to shine a spotlight on The Lantern Hostel.</p>
            <p>Ready for a sneak peek? Watch our video below to explore The Lantern Hostel’s welcoming vibe, then come experience it for yourself. Adventure and camaraderie are waiting, so don’t miss out on this Chalong gem hostel in Phuket, Thailand!</p>

            <h5>Address: 76/9 Moo 6, Chao Fa 42 Rd, Chalong, phuket, Phuket 83130</h5>
            <h5>Phone: +66 98 670 1668</h5>
    </div>
    )
}

export default LanternDescription;