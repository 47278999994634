// main-component/router/index.js
import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Homepage from '../HomePage'
import Cherdchai from '../Cherdchai'
import DestinationPage from '../DestinationPage'
import DestinationPage2 from '../DestinationPage2';
import DestinationCc from '../../components/DestinationCherdchai';
import AboutPage from '../AboutPage'
import RecommendationsPhuket from '../RecommendationsPhuket'
import HostelsPhuket from '../HostelsPhuket'
import BlogPage from '../BlogPage'
import BlogPageLeft from '../BlogPageLeft'
import BlogPageFullwidth from '../BlogPageFullwidth'
import BlogDetails from '../BlogDetails'
import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide'
import BlogDetailsFull from '../BlogDetailsFull'
import ErrorPage from '../ErrorPage'
import ContactPage from '../ContactPage'


const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Existing Routes */}
          <Route exact path='/' element={<Homepage />} />
          <Route path='home' element={<Homepage />} />
          {/*<Route path='home3' element={<Homepage3 />} />*/}
          {/*<Route path='room' element={<RoomPage />} />
          <Route path='room-single' element={<RoomSinglePage />} />*/}
          <Route path='destination' element={<DestinationPage />} />
          <Route path='destination2' element={<DestinationPage2 />} />
          <Route path='about' element={<AboutPage />} />

          {/*<Route path='faq' element={<FaqPage />} />*/}
          <Route path='404' element={<ErrorPage />} />
          <Route path='contact' element={<ContactPage />} />

          {/* New Route for Cherdchai */}
          <Route path='cherdchai' element={<Cherdchai />} />
          <Route path='cherdchai-destinations' element={<DestinationCc />} />

          {/* New Route for Recommendations */}
          {/*<Route path='recommendations' element={<Recommendations />} />*/}
          <Route path='recommendations-phuket' element={<RecommendationsPhuket />} />
          <Route path='hostels-phuket' element={<HostelsPhuket />} />
          {/* Blog Routes */}
          <Route path='blog' element={<BlogPage />} />
          <Route path='blog-left' element={<BlogPageLeft />} />
          <Route path='blog-fullwidth' element={<BlogPageFullwidth />} />
          
          {/* Updated Blog Details Route with dynamic id */}
          <Route path='blog-details/:id' element={<BlogDetails />} />
          <Route path='blog-details-left' element={<BlogDetailsLeftSiide />} />
          <Route path='blog-details-fullwidth' element={<BlogDetailsFull />} />
          
          {/* Authentication Routes 
          <Route path='login' element={<LoginPage />} />
          <Route path='signup' element={<SignUpPage />} />
          <Route path='forgot-password' element={<ForgotPassword />} /> */}
          
          {/* Catch-All Route for 404 */}
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AllRoute;
