import React from 'react'
import LanternDescription from './Lantern/description'
import './style.css'

const HostelsPhuket = (props) => {

    return(
        <section className="service-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 col-12 order-lg-2">
                        <div className="service-single-content">
                           <LanternDescription/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HostelsPhuket;