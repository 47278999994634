import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import RecommendationsPhuket from '../../components/RecommendationsPhuket';


const ServicePage =() => {
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={'Recommendations'} pagesub={'Phuket'}/> 
            <RecommendationsPhuket/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ServicePage;
