// src/data/destinationsData.js
// Importing destination images with names based on destination names
import bangkokImg from '../images/destination/bangkok.jpeg';
import chiangMaiImg from '../images/destination/chiangmai.jpeg';
import phuketImg from '../images/destination/phiphi.jpeg';
import phraeImg from '../images/destination/phrae.jpg';
import udonThaniImg from '../images/destination/udonthani.jpeg';
import sukhothaiImg from '../images/destination/sukhothai.jpeg';
import chiangRaiImg from '../images/destination/chiangrai.jpg';
import krabiImg from '../images/destination/krabi.jpeg';
import hatYaiImg from '../images/destination/hatyai1.jpg';
import khaoSokImg from '../images/destination/khaosok.jpg';
import kohChangImg from '../images/destination/kohchang.jpg';
import kohSamuiImg from '../images/destination/kohsamui.jpg';
import ranongImg from '../images/destination/ranong.png';
import takImg from '../images/destination/tak.png';
import kohKoodImg from '../images/destination/kohkood.jpg';
import buriramImg from '../images/destination/buriram.jpg';
import ubonRatchathaniImg from '../images/destination/ubonratchathani2.jpg';
import templeImg from '../images/destination/temple4.jpg';
import maeSotImg from '../images/destination/mae_sot.jpg';
import nongKhaiImg from '../images/destination/nong_khai.jpg';
import loeiImg from '../images/destination/loei.jpg';
import kanchanaburiImg from '../images/destination/kanchanaburi.jpg';
import nakhornsiThammaratImg from '../images/destination/nakhonsithammarat.jpg';
import surathaniImg from '../images/destination/surathani.jpg';
import khonkaenImg from '../images/destination/khonkaen.jpg';
import phitsanulokImg from '../images/destination/isan.jpg';
import phetchabunImg from '../images/destination/northern_thailand.jpg';
import phatthalungImg from '../images/destination/temple3.jpg';
import phangngaImg from '../images/destination/krabi.jpeg';
import phayaoImg from '../images/destination/phayao.jpg';
import phichitImg from '../images/destination/phrae.jpg';
import UttaraditImg from '../images/destination/pai.png';
import nanImg from '../images/destination/isan.jpg';
import nakhonSawanImg from '../images/destination/isan.jpg';
import nakhonRatchasimaImg from '../images/destination/temple4.jpg';
import mukdahanImg from '../images/destination/isan.jpg';
import mahasarakhmImg from '../images/destination/isan.jpg';
import lamphunImg from '../images/destination/isan.jpg';
import songkhlaImg from '../images/destination/hatyai2.jpg';
import sisaketImg from '../images/destination/isan.jpg';
import surinImg from '../images/destination/isan.jpg';
import sahonNakhonImg from '../images/destination/isan.jpg';
import rayongImg from '../images/destination/temple4.jpg';
import prachuapKhiriKhanImg from '../images/destination/huahin.jpg';
import phetchaburiImg from '../images/destination/temple4.jpg';
import tratImg from '../images/destination/temple4.jpg';
import samutSakhonImg from '../images/destination/temple4.jpg';
import saraburiImg from '../images/destination/saraburi.jpg';
import sakaeoImg from '../images/destination/temple4.jpg';
import roietImg from '../images/destination/temple4.jpg';
import lampangImg from '../images/destination/northern_thailand.jpg';
import kamphaengPhetImg from '../images/destination/temple4.jpg';
import chiangKhanImg from '../images/destination/tak.jpg';
import chayapumImg from '../images/destination/temple4.jpg';
import chanthaBuriImg from '../images/destination/temple4.jpg';
import buengkanImg from '../images/destination/temple4.jpg';
import amnatCharoenImg from '../images/destination/temple4.jpg';
import lopburiImg from '../images/destination/hatyai.jpeg';
import yasothonImg from '../images/destination/nakhon_phanom.jpg';
import nongBuaLamPhuImg from '../images/destination/temple4.jpg';
import nakhonPhanomImg from '../images/destination/nakhon_phanom.jpg';
import kalasinImg from '../images/destination/isan.jpg';
import khaoLakImg from '../images/destination/khaosok2.jpg';
import chumponImg from '../images/destination/huahin.jpg';
import maeSaiImg from '../images/destination/maehongson.jpg';

// Destination Data
const destinationsData = [
    
        {
            DImg: krabiImg,
            Title: 'Bangkok ↔ Krabi',
            price: '675THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/krabi/5909',
        },
        {
            DImg: phuketImg,
            Title: 'Bangkok ↔ Phuket',
            price: '680THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/phuket/6056',
        },
        {
            DImg: surathaniImg,
            Title: 'Bangkok ↔ Surat Thani',
            price: '558THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/surat-thani/6119',
        },
        {
            DImg: phangngaImg,
            Title: 'Bangkok ↔ Phangnga',
            price: '641THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/phangnga/6028',
        },
        {
            DImg: hatYaiImg,
            Title: 'Bangkok ↔ Songkhla',
            price: '824THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/songkhla/6105',
        },
        {
            DImg: templeImg,
            Title: 'Bangkok ↔ Trang',
            price: '711THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/trang/5958',
        },
        {
            DImg: templeImg,
            Title: 'Bangkok ↔ Yala',
            price: '891THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/yala/6063',
        },
        {
            DImg: templeImg,
            Title: 'Bangkok ↔ Pattani',
            price: '869THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/pattani/6014',
        },
        {
            DImg: nakhornsiThammaratImg,
            Title: 'Bangkok ↔ Nakhon Si Thammarat',
            price: '666THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/nakhon-si-thammatat/5986',
        },
        {
            DImg: templeImg,
            Title: 'Bangkok ↔ Narathiwat',
            price: '949THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/narathiwat/5993',
        },
        {
            DImg: ranongImg,
            Title: 'Bangkok ↔ Ranong',
            price: '491THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/ranong/6070',
        },
        {
            DImg: templeImg,
            Title: 'Bangkok ↔ Satun',
            price: '817THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/satun/6112',
        },
        {
            DImg: bangkokImg,
            Title: 'Bangkok ↔ Chumphon',
            price: '558THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/chumphon/5944',
        },
        {
            DImg: chiangMaiImg,
            Title: 'Bangkok ↔ Chiang Mai',
            price: '594THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/chiang-mai/3494',
        },
        {
            DImg: chiangRaiImg,
            Title: 'Bangkok ↔ Chiang Rai',
            price: '650THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/chiang-rai/834',
        },
        {
            DImg: lampangImg,
            Title: 'Bangkok ↔ Lampang',
            price: '511THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/lampang/694',
        },
        {
            DImg: phatthalungImg,
            Title: 'Bangkok ↔ Phatthalung',
            price: '706THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/phatthalung/6049',
        },
        {
            DImg: UttaraditImg,
            Title: 'Bangkok ↔ Uttaradit',
            price: '405THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/uttaradit/813',
        },
        {
            DImg: phraeImg,
            Title: 'Bangkok ↔ Phrae',
            price: '468THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/phrae/890',
        },
        {
            DImg: nanImg,
            Title: 'Bangkok ↔ Nan',
            price: '567THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/nan/505',
        },
        {
            DImg: phayaoImg,
            Title: 'Bangkok ↔ Phayao',
            price: '576THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/phayao/540',
        },
        {
            DImg: nakhonSawanImg,
            Title: 'Bangkok ↔ Nakhon Sawan',
            price: '313THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/nakhon-sawan/484',
        },
        {
            DImg: kamphaengPhetImg,
            Title: 'Bangkok ↔ Kamphaeng Phet',
            price: '313THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/kamphaeng-phet/344',
        },
        {
            DImg: takImg,
            Title: 'Bangkok ↔ Tak',
            price: '362THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/tak/428',
        },
        {
            DImg: lamphunImg,
            Title: 'Bangkok ↔ Lamphun',
            price: '571THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/lamphun/701',
        },
        {
            DImg: sukhothaiImg,
            Title: 'Bangkok ↔ Sukhothai',
            price: '365THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/sukhothai/771',
        },
        {
            DImg: hatYaiImg,
            Title: 'Bangkok ↔ Hat Yai',
            price: '785THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/hat-yai/6091',
        },
        {
            DImg: phitsanulokImg,
            Title: 'Bangkok ↔ Phisanulok',
            price: '320THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/phisanulok1/596',
        },
        {
            DImg: loeiImg,
            Title: 'Bangkok ↔ Loei',
            price: '472THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/loei/883',
        },
        {
            DImg: chiangKhanImg,
            Title: 'Bangkok ↔ Chiang Khan',
            price: '596THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/chiang-khan/1177',
        },
        {
            DImg: buriramImg,
            Title: 'Bangkok ↔ Buri Ram',
            price: '283THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/buri-rom/519',
        },
        {
            DImg: surinImg,
            Title: 'Bangkok ↔ Surin',
            price: '324THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/surin/764',
        },
        {
            DImg: sisaketImg,
            Title: 'Bangkok ↔ Si Sa Ket',
            price: '413THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/si-sa-ket/708',
        },
        {
            DImg: ubonRatchathaniImg,
            Title: 'Bangkok ↔ Ubon Ratchathani',
            price: '504THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/ubon-ratchathani/827',
        },
        {
            DImg: yasothonImg,
            Title: 'Bangkok ↔ Yasothon',
            price: '443THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok-bus-terminal-chatuchak-mo-chit-2/yasothon-bus-terminal/645',
        },
        {
            DImg: chayapumImg,
            Title: 'Bangkok ↔ Chaiyaphum',
            price: '292THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/chaiyaphum/386',
        },
        {
            DImg: nakhonRatchasimaImg,
            Title: 'Bangkok ↔ Nakhon Ratchasima',
            price: '232THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/nakhon-ratchasima/4782',
        },
        {
            DImg: nongBuaLamPhuImg,
            Title: 'Bangkok ↔ Nong Bua Lam Phu',
            price: '452THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/nong-bua-lam-phu/785',
        },
        {
            DImg: khonkaenImg,
            Title: 'Bangkok ↔ Khon Kaen',
            price: '380THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/khon-kaen/365',
        },
        {
            DImg: udonThaniImg,
            Title: 'Bangkok ↔ Udon Thani',
            price: '473THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/Udon-Thani/2094',
        },
        {
            DImg: nongKhaiImg,
            Title: 'Bangkok ↔ Nong Khai',
            price: '515THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/nong-khai/778',
        },
        {
            DImg: mahasarakhmImg,
            Title: 'Bangkok ↔ Maha Sarakham',
            price: '400THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/maha-sarakham/624',
        },
        {
            DImg: roietImg,
            Title: 'Bangkok ↔ Roi Et',
            price: '432THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/roi-et/659',
        },
        {
            DImg: kalasinImg,
            Title: 'Bangkok ↔ Kalasin',
            price: '436THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/kalasin/337',
        },
        {
            DImg: sahonNakhonImg,
            Title: 'Bangkok ↔ Sakon Nakhon',
            price: '620THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/sakon-nakhon/5321',
        },
        {
            DImg: nakhonPhanomImg,
            Title: 'Bangkok ↔ Naknon Phanom',
            price: '605THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/naknon-phanom/442',
        },
        {
            DImg: mukdahanImg,
            Title: 'Bangkok ↔ Mukdahan',
            price: '535THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/mukdahon/631',
        },
        {
            DImg: phetchaburiImg,
            Title: 'Bangkok ↔ Phetchaburi',
            price: '371THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/phetchaburi/3774',
        },
        {
            DImg: prachuapKhiriKhanImg,
            Title: 'Bangkok South ↔ Prachuapkhiri Khan',
            price: '371THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/prachuapkhiri-khan/6210',
        },
        {
            DImg: prachuapKhiriKhanImg,
            Title: 'Bangkok ↔ Prachuapkhiri Khan',
            price: '371THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/prachuapkhiri-khan/1296',
        },
        {
            DImg: chanthaBuriImg,
            Title: 'Bangkok ↔ Chanthaburi',
            price: '227THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/chanthaburi/372',
        },
        {
            DImg: kanchanaburiImg,
            Title: 'Bangkok ↔ Kanchanaburi',
            price: '142THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/kanchanaburi/330',
        },
        {
            DImg: tratImg,
            Title: 'Bangkok ↔ Trat',
            price: '288THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/trat/414',
        },
        {
            DImg: amnatCharoenImg,
            Title: 'Bangkok ↔ Amnat Charoen',
            price: '486THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/amnat-charoen/792',
        },
        {
            DImg: samutSakhonImg,
            Title: 'Bangkok ↔ Samut Sakhon',
            price: '371THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/mahachai/5545',
        },
        {
            DImg: lopburiImg,
            Title: 'Bangkok ↔ Lopburi',
            price: '306THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/lam-narai/3578',
        },
        {
            DImg: buengkanImg,
            Title: 'Bangkok ↔ Buengkan',
            price: '623THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/bungkan/512',
        },
        {
            DImg: rayongImg,
            Title: 'Bangkok ↔ Rayong Terminal 2',
            price: '180THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/ranong2/7918',
        },
        {
            DImg: chanthaBuriImg,
            Title: 'Bangkok ↔ Chanthaburi',
            price: '218THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/chanthaburi/7904',
        },
        {
            DImg: tratImg,
            Title: 'Bangkok ↔ Trat',
            price: '279THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/trat/7911',
        },
        {
            DImg: saraburiImg,
            Title: 'Bangkok ↔ Saraburi',
            price: '83THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/saraburi/743',
        },
        {
            DImg: sakaeoImg,
            Title: 'Bangkok ↔ Sakeao Wang Nam Yen',
            price: '270THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/wang-nam-yen/8492',
        },
        {
            DImg: phetchabunImg,
            Title: 'Bangkok ↔ Phetchabun Lom Sak',
            price: '340THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/lom-sak/876',
        },
        {
            DImg: loeiImg,
            Title: 'Bangkok ↔ Loei Pha Nok Khao',
            price: '455THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/pha-nok-khao/1198',
        },
        {
            DImg: phetchabunImg,
            Title: 'Bangkok ↔ Phetchabun Khao Kho',
            price: '407THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/khao-kho/2780',
        },
        {
            DImg: loeiImg,
            Title: 'Bangkok ↔ Loei Wang Saphung',
            price: '455THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/wang-saphung/1219',
        },
        {
            DImg: kohSamuiImg,
            Title: 'Bangkok ↔ Koh Samui',
            price: '619THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/koh-samui/4600',
        },
        {
            DImg: yasothonImg,
            Title: 'Bangkok ↔ Yasothon Kham Khuean Kaew',
            price: '500THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/Kham-Khuean-Kaew/2171',
        },
        {
            DImg: tratImg,
            Title: 'Bangkok ↔ Trat Saen Tung',
            price: '290THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/Saen-Tung/8254',
        },
        {
            DImg: chiangRaiImg,
            Title: 'Bangkok ↔ Chiang Rai Mae Sai',
            price: '698THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/mae-sai/848',
        },
        {
            DImg: nanImg,
            Title: 'Bangkok ↔ Nan Thung Chang',
            price: '641THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/thung-chang/2353',
        },
        {
            DImg: loeiImg,
            Title: 'Bangkok ↔ Loei Phu Kradueng',
            price: '550THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/phu-kradueng/5307',
        },
        {
            DImg: surathaniImg,
            Title: 'Phuket ↔ Surat Thani',
            price: '250THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phuket/surat-thani/30843',
        },
        {
            DImg: khaoSokImg,
            Title: 'Phuket ↔ Khao Sok',
            price: '310THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phuket/khao-sok/31067',
        },
        {
            DImg: bangkokImg,
            Title: 'Phuket ↔ Bangkok',
            price: '680THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phuket/bangkok/30724',
        },
        {
            DImg: nakhornsiThammaratImg,
            Title: 'Phuket ↔ Nakhon Si Thammarat',
            price: '320THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phuket/nakhon-si-thammatat/30766',
        },
        {
            DImg: krabiImg,
            Title: 'Phuket ↔ Krabi',
            price: '320THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phuket-bus-terminal-2/lignite-nuea-khlong/30892',
        },
        {
            DImg: phangngaImg,
            Title: 'Phuket ↔ Phangnga',
            price: '641THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phuket/phangnga/30780',
        },
        {
            DImg: chiangMaiImg,
            Title: 'Phuket ↔ Chiang Mai',
            price: '2008THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phuket/chiang-mai/31074',
        },
        {
            DImg: khaoLakImg,
            Title: 'Phuket ↔ Khao Lak',
            price: '130THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phuket/khao-lak/30969',
        },
        {
            DImg: bangkokImg,
            Title: 'Krabi ↔ Bangkok',
            price: '675THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/krabi/bangkok/8',
        },
        {
            DImg: nakhornsiThammaratImg,
            Title: 'Krabi ↔ Nakhon Si Thammarat',
            price: '320THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/krabi/nakhon-si-thammatat/50',
        },
        {
            DImg: phuketImg,
            Title: 'Krabi ↔ Phuket',
            price: '320THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/krabi/phuket/78',
        },
        {
            DImg: bangkokImg,
            Title: 'Surat Thani ↔ Bangkok',
            price: '558THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/surat-thani/bangkok/41686',
        },
        {
            DImg: chumponImg,
            Title: 'Surat Thani ↔ Chumpon',
            price: '619THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/surat-thani/chumpon/41693',
        },
        {
            DImg: songkhlaImg,
            Title: 'Surat Thani ↔ Songkhla',
            price: '824THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/surat-thani/songkhla/41791',
        },
        {
            DImg: nakhornsiThammaratImg,
            Title: 'Surat Thani ↔ Nakhon Si Thammarat',
            price: '666THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/surat-thani/nakhon-si-thammatat/41721',
        },
        {
            DImg: bangkokImg,
            Title: 'Phangnga ↔ Bangkok',
            price: '715THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phangnga/bangkok/27665',
        },
        {
            DImg: phuketImg,
            Title: 'Phangnga ↔ Phuket',
            price: '715THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phangnga/phuket/27721',
        },
        {
            DImg: phuketImg,
            Title: 'Khao Sok ↔ Phuket',
            price: '310THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/khao-sok/phuket/136046',
        },
        {
            DImg: bangkokImg,
            Title: 'Phatthalung ↔ Bangkok',
            price: '706THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phatthalung/bangkok/28589',
        },
        {
            DImg: udonThaniImg,
            Title: 'Ubon Ratchathani ↔ Udon Thani',
            price: '373THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/Ubon-Ratchathani/Udon-Thani/45879',
        },
        {
            DImg: nakhonRatchasimaImg,
            Title: 'Ubon Ratchathani ↔ Nakhon Ratchasima',
            price: '420THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/Ubon-Ratchathani/nakhon-ratchasima/45620',
        },
        {
            DImg: bangkokImg,
            Title: 'Songkhla ↔ Bangkok',
            price: '824THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/songkhla/bangkok/39096',
        },
        {
            DImg: surathaniImg,
            Title: 'Songkhla ↔ Surat Thani',
            price: '824THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/songkhla/surat-thani/39159',
        },
        {
            DImg: ubonRatchathaniImg,
            Title: 'Udon Thani ↔ Ubon Ratchathani',
            price: '373THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/udon-thani/Ubon-Ratchathani/84890',
        },
        {
            DImg: bangkokImg,
            Title: 'Udon Thani ↔ Bangkok',
            price: '473THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/udon-thani/bangkok/84736',
        },
        {
            DImg: bangkokImg,
            Title: 'Pattani ↔ Bangkok',
            price: '869THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/pattani/bangkok/25495',
        },
        {
            DImg: nakhonPhanomImg,
            Title: 'Ubon Ratchathani ↔ Naknon Phanom',
            price: '220THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/Ubon-Ratchathani/naknon-phanom/45613',
        },
        {
            DImg: bangkokImg,
            Title: 'Nakhon Si Thammarat ↔ Bangkok',
            price: '666THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/nakhon-si-thammarat/bangkok/21946',
        },
        {
            DImg: phuketImg,
            Title: 'Nakhon Si Thammarat ↔ Phuket',
            price: '320THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/nakhon-si-thammarat/phuket/22023',
        },
        {
            DImg: krabiImg,
            Title: 'Nakhon Si Thammarat ↔ Krabi',
            price: '320THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/nakhon-si-thammarat/krabi/21932',
        },
        {
            DImg: surathaniImg,
            Title: 'Nakhon Si Thammarat ↔ Surat Thani',
            price: '824THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/nakhon-si-thammarat/surat-thani/22044',
        },
        {
            DImg: bangkokImg,
            Title: 'Narathiwat ↔ Bangkok',
            price: '1557THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/narathiwat/bangkok/23409',
        },
        {
            DImg: bangkokImg,
            Title: 'Trang ↔ Bangkok',
            price: '711THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/trang/bangkok/16794',
        },
        {
            DImg: bangkokImg,
            Title: 'Ranong ↔ Bangkok',
            price: '553THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/ranong/bangkok/34868',
        },
        {
            DImg: bangkokImg,
            Title: 'Satun ↔ Bangkok',
            price: '817THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/satun/bangkok/39460',
        },
        {
            DImg: surathaniImg,
            Title: 'Chumphon ↔ Surat Thani',
            price: '558THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/chumphon/surat-thani/15681',
        },
        {
            DImg: bangkokImg,
            Title: 'Chumphon ↔ Bangkok',
            price: '400THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/chumphon/bangkok/15562',
        },
        {
            DImg: bangkokImg,
            Title: 'Chiang Mai ↔ Bangkok',
            price: '594THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/chiang-mai/bangkok/140015',
        },
        {
            DImg: chiangRaiImg,
            Title: 'Chiang Mai ↔ Chiang Rai',
            price: '196THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/chiang-mai-bus-terminal-3-arcade/chiang-rai-bus-terminal-1/140687',
        },
        {
            DImg: phuketImg,
            Title: 'Chiang Mai ↔ Phuket',
            price: '2008THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/chiang-mai/phuket/140141',
        },
        {
            DImg: maeSaiImg,
            Title: 'Chiang Mai ↔ Mae Sai',
            price: '247THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/chiang-mai/mae-sai/140239',
        },
        {
            DImg: maeSotImg,
            Title: 'Chiang Mai ↔ Mae Sot',
            price: '351THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/chiang-mai/mae-sot/140071',
        },
        {
            DImg: sahonNakhonImg,
            Title: 'Chiang Mai ↔ Sakon Nakhon',
            price: '846THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/chiang-mai-bus-terminal-3-arcade/sakon-nakhon-bus-terminal-2/262970',
        },
        {
            DImg: takImg,
            Title: 'Chiang Mai ↔ Tak',
            price: '371THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/chiang-mai-bus-terminal-2-arcade/tak-bus-terminal/47818',
        },
        {
            DImg: bangkokImg,
            Title: 'Hat Yai ↔ Bangkok',
            price: '785THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/hat-yai/bangkok/38431',
        },
        {
            DImg: phayaoImg,
            Title: 'Hat Yai ↔ Phayao',
            price: '1579THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/hat-yai/phayao/38529',
        },
        {
            DImg: bangkokImg,
            Title: 'Nakhon Ratchasima ↔ Bangkok',
            price: '232THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/nakhon-ratchasima/bangkok/181364',
        },
        {
            DImg: nakhonPhanomImg,
            Title: 'Nakhon Ratchasima ↔ Nakhon Phanom',
            price: '605THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/nakhon-ratchasima/nakhon-phanom/19265',
        },
        {
            DImg: khonkaenImg,
            Title: 'Nakhon Ratchasima ↔ Khon Kaen',
            price: '146THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/nakhon-ratchasima/khon-kaen/19251',
        },
        {
            DImg: sahonNakhonImg,
            Title: 'Nakhon Ratchasima ↔ Sakon Nakhon',
            price: '531THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/nakhon-ratchasima/sakon-nakhon/19356',
        },
        {
            DImg: bangkokImg,
            Title: 'Chiang Rai ↔ Bangkok',
            price: '650THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/chiang-rai/bangkok/46376',
        },
        {
            DImg: chiangMaiImg,
            Title: 'Chiang Rai ↔ Chiang Mai',
            price: '196THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/chiang-rai-bus-terminal-1/chiang-mai-bus-terminal-3-arcade/174490',
        },
        {
            DImg: bangkokImg,
            Title: 'Lampang ↔ Bangkok',
            price: '511THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/lampang/bangkok/36450',
        },
        {
            DImg: roietImg,
            Title: 'Ubon Ratchathani ↔ Roi Et',
            price: '187THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/ubon-ratchathani/roi-et/45669',
        },
        {
            DImg: khonkaenImg,
            Title: 'Ubon Ratchathani ↔ Khon Kaen',
            price: '294THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/ubon-ratchathani/khon-kaen/45606',
        },
        {
            DImg: bangkokImg,
            Title: 'Roi Et ↔ Bangkok',
            price: '432THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/roi-et/bangkok/33664',
        },
        {
            DImg: mahasarakhmImg,
            Title: 'Ubon Ratchathani ↔ Maha Sarakham',
            price: '227THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/ubon-ratchathani/maha-sarakham/45641',
        },
        {
            DImg: UttaraditImg,
            Title: 'Ubon Ratchathani ↔ Uttaradit',
            price: '699THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/ubon-ratchathani/uttaradit/45725',
        },
        {
            DImg: bangkokImg,
            Title: 'Ubon Ratchathani ↔ Bangkok',
            price: '524THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/ubon-ratchathani/bangkok/45585',
        },
        {
            DImg: bangkokImg,
            Title: 'Maha Sarakham ↔ Bangkok',
            price: '400THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/maha-sarakham/bangkok/31333',
        },
        {
            DImg: bangkokImg,
            Title: 'Buri Ram ↔ Bangkok',
            price: '283THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/buri-ram/bangkok/25201',
        },
        {
            DImg: bangkokImg,
            Title: 'Uttaradit ↔ Bangkok',
            price: '405THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/uttaradit/bangkok/44843',
        },
        {
            DImg: ubonRatchathaniImg,
            Title: 'Uttaradit ↔ Ubon Ratchathani',
            price: '699THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/uttaradit/ubon-ratchathani/45011',
        },
        {
            DImg: bangkokImg,
            Title: 'Phrae ↔ Bangkok',
            price: '468THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phrae/bangkok/49162',
        },
        {
            DImg: bangkokImg,
            Title: 'Nan ↔ Bangkok',
            price: '567THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/nan/bangkok/24375',
        },
        {
            DImg: bangkokImg,
            Title: 'Nakhon Sawan ↔ Bangkok',
            price: '313THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/nakhon-sawan/bangkok/22513',
        },
        {
            DImg: bangkokImg,
            Title: 'Kamphaeng Phet ↔ Bangkok',
            price: '313THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/kamphaeng-phet/bangkok/11677',
        },
        {
            DImg: bangkokImg,
            Title: 'Phayao ↔ Bangkok',
            price: '576THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phayao/bangkok/26853',
        },
        {
            DImg: hatYaiImg,
            Title: 'Phayao ↔ Hat Yai',
            price: '1579THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phayao/hat-yai/26958',
        },
        {
            DImg: bangkokImg,
            Title: 'Tak ↔ Bangkok',
            price: '362THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/tak/bangkok/17641',
        },
        {
            DImg: chiangMaiImg,
            Title: 'Tak ↔ Chiang Mai',
            price: '371THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/tak-bus-terminal/chiang-mai-bus-terminal-2-arcade/17788',
        },
        {
            DImg: bangkokImg,
            Title: 'Lamphun ↔ Bangkok',
            price: '571THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/lamphun/bangkok/37213',
        },
        {
            DImg: bangkokImg,
            Title: 'Sukhothai ↔ Bangkok',
            price: '365THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/sukhothai/bangkok/42414',
        },
        {
            DImg: bangkokImg,
            Title: 'Phisanulok Terminal 1 ↔ Bangkok',
            price: '320THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phisanulok1/bangkok/29240',
        },
        {
            DImg: bangkokImg,
            Title: 'Phisanulok Terminal 2 ↔ Bangkok',
            price: '320THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phisanulok2/bangkok/29632',
        },
        {
            DImg: bangkokImg,
            Title: 'Phichit ↔ Bangkok',
            price: '299THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phichit/bangkok/29072',
        },
        {
            DImg: phichitImg,
            Title: 'Bangkok ↔ Phichit',
            price: '299THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok-bus-terminal-chatuchak-mo-chit-2/pichit-bus-terminal/582',
        },
        {
            DImg: bangkokImg,
            Title: 'Phetchabun ↔ Bangkok',
            price: '306THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phetchabun/bangkok/869',
        },
        {
            DImg: bangkokImg,
            Title: 'Phetchabun ↔ Bangkok',
            price: '351THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phetchabun/bangkok/48364',
        },
        {
            DImg: bangkokImg,
            Title: 'Loei ↔ Bangkok',
            price: '550THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/loei/bangkok/48679',
        },
        {
            DImg: bangkokImg,
            Title: 'Chiang Khan ↔ Bangkok',
            price: '596THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/chiang-khan/bangkok/61622',
        },
        {
            DImg: bangkokImg,
            Title: 'Surin ↔ Bangkok',
            price: '324THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/surin/bangkok/42162',
        },
        {
            DImg: bangkokImg,
            Title: 'Si Sa Ket ↔ Bangkok',
            price: '413THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/si-sa-ket/bangkok/37486',
        },
        {
            DImg: bangkokImg,
            Title: 'Chaiyaphum ↔ Bangkok',
            price: '292THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/chaiyaphum/bangkok/15065',
        },
        {
            DImg: bangkokImg,
            Title: 'Nong Bua Lam Phu ↔ Bangkok',
            price: '486THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/nong-bua-lam-phu/bangkok/43044',
        },
        {
            DImg: bangkokImg,
            Title: 'Khon Kaen ↔ Bangkok',
            price: '380THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/khon-kaen/bangkok/13259',
        },
        {
            DImg: ubonRatchathaniImg,
            Title: 'Khon Kaen ↔ Ubon Ratchathani',
            price: '294THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/khon-kaen/ubon-tatchathani/13427',
        },
        {
            DImg: nakhonRatchasimaImg,
            Title: 'Khon Kaen ↔ Nakhon Ratchasima',
            price: '146THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/khon-kaen/nakhon-ratchasima/13301',
        },
        {
            DImg: bangkokImg,
            Title: 'Nong Khai ↔ Bangkok',
            price: '515THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/nong-kaai/bangkok/42666',
        },
        {
            DImg: bangkokImg,
            Title: 'Kalasin ↔ Bangkok',
            price: '482THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/kalasin/bangkok/11292',
        },
        {
            DImg: bangkokImg,
            Title: 'Sakon Nakhom ↔ Bangkok',
            price: '620THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/sakon-nakhom/bangkok/198388',
        },
        {
            DImg: bangkokImg,
            Title: 'Nakonon Phanom ↔ Bangkok',
            price: '605THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/nakonon-phanom/bangkok/18250',
        },
        {
            DImg: bangkokImg,
            Title: 'Mukdahan ↔ Bangkok',
            price: '535THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/mukdahan/bangkok/32005',
        },
        {
            DImg: bangkokImg,
            Title: 'Phetchaburi ↔ Bangkok',
            price: '457THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/phetchaburi/bangkok/148779',
        },
        {
            DImg: bangkokImg,
            Title: 'Prachuapkhiri Khan ↔ Bangkok',
            price: '457THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/prachuapkhiri-khan/bangkok/64464',
        },
        {
            DImg: bangkokImg,
            Title: 'Prachuapkhiri Khan ↔ Bangkok',
            price: '457THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/prachuapkhiri-khan/bangkok/64457',
        },
        {
            DImg: bangkokImg,
            Title: 'Chanthaburi ↔ Bangkok',
            price: '227THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/chanthaburi/bangkok/14260',
        },
        {
            DImg: bangkokImg,
            Title: 'Chanthaburi ↔ Bangkok Ekkamai',
            price: '218THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/chanthaburi/bangkok/14267',
        },
        {
            DImg: bangkokImg,
            Title: 'Amnat Charoen ↔ Bangkok',
            price: '567THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/amnat-charoen/bangkok/44234',
        },
        {
            DImg: bangkokImg,
            Title: 'Kanchanaburi ↔ Bangkok',
            price: '347THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/kanchanaburi/bangkok/11082',
        },
        {
            DImg: bangkokImg,
            Title: 'Trat ↔ Bangkok',
            price: '288THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/trat/bangkok/17039',
        },
        {
            DImg: bangkokImg,
            Title: 'Trat ↔ Bangkok',
            price: '279THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/trat/bangkok/17046',
        },
        {
            DImg: bangkokImg,
            Title: 'Bungkan ↔ Bangkok',
            price: '623THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bungkan/bangkok/24844',
        },
        {
            DImg: bangkokImg,
            Title: 'Mahachai ↔ Bangkok',
            price: '457THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/mahachai/bangkok/216287',
        },
        {
            DImg: bangkokImg,
            Title: 'Lam Narai ↔ Bangkok',
            price: '351THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/lam-narai/bangkok/143039',
        },
        {
            DImg: bangkokImg,
            Title: 'Ang Thong ↔ Bangkok',
            price: '313THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/ang-thong/bangkok/43541',
        },
        {
            DImg: bangkokImg,
            Title: 'Uthai Thani ↔ Bangkok',
            price: '317THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/uthai-thani/bangkok/45536',
        },
        {
            DImg: bangkokImg,
            Title: 'Rayong Terminal 2 ↔ Bangkok Mo Chit',
            price: '180THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/rayong-bus-terminal-2/minibus-station-chatuchak-mochit-new-van-terminal/35239',
        },
        {
            DImg: bangkokImg,
            Title: 'Rayong Terminal 2 ↔ Bangkok Ekkamai',
            price: '180THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/rayong-bus-terminal-2/bangkok-bus-terminal-ekkamai/35113',
        },
        {
            DImg: kohChangImg,
            Title: 'Bangkok ↔ Koh Chang',
            price: '650THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/bangkok/koh-chang/178375',
        },
        {
            DImg: kohKoodImg,
            Title: 'Bangkok ↔ Koh Kood',
            price: '1100THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/khao-san-road/koh-kood/202980',
        },
        {
            DImg: hatYaiImg,
            Title: 'Hat Yai ↔ Phuket',
            price: '396THB',
            Date: 'Daily',
            link: 'https://booking.ogtix.asia/bus/hat-yai-bus-terminal-1/phuket-bus-terminal-2/38564',
        },

    
];

export default destinationsData;
